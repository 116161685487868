import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export default ({ data }) => {
  const page = data.craft.page
  const seo = page.seomatic

  return (
    <Layout seo={seo}>
      <div className="page-content-centered">
        <div className="wrapper">
          <div className="columns is-centered text-center">
            <div className="column is-12 is-8-tablet is-6-desktop is-4-widescreen">
              <h1 className="subhead mb-3">{page.title}</h1>
              <div className="mb-5">
                <p
                  className="h-2 h--cms-title"
                  dangerouslySetInnerHTML={{
                    __html: page.alternateTitle,
                  }}
                />
              </div>
              <div
                className="user-content"
                dangerouslySetInnerHTML={{
                  __html: page.body,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    craft {
      page: entry(section: "error404") {
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_error404_error404_Entry {
          alternateTitle
          body
        }
      }
    }
  }
`
